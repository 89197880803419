import React, { useState } from 'react';
// import './modals.css';
import { Card, CardContent, CardActions, Button, Typography } from '@mui/material';
import PillBox from '../pills/PillBox';
import Modal from '../../Utilities/Modal';

const EditGautraModal = ({ row, onModalEdit, setIsOpen }) => {
  const [allNames, setAllNames] = useState(row.all_names);
  let [altName, setAltName] = useState('');
  // const data = {};
  //  onModalEdit(data);
  const finalEditAction = () => {
    const data = {
      all_names: allNames,
      display_name: allNames.join('/'),
      subcastes: [],
    };
    console.log('passing the data from modal', data);
    onModalEdit(data, row._id);
  };
  const modalClose = () => {
    console.log('closing modal');
    setIsOpen(row._id);
  };

  const handleInputChange = (event) => {
    setAltName(event.target.value);
  };

  const createNewPill = (e) => {
    e.preventDefault();
    if (altName !== '') {
      setAllNames([...allNames, altName]), setAltName('');
    }
  };
  const clearAllPills = () => {
    setAllNames([]);
  };

  const removePill = (e) => {
    setAllNames(allNames.filter((item, x) => e !== x));
  };
  // const updatingData = (data) => {
  //   setAllNames(data);
  // };
  return (
    <>
      {/* <div className="darkBG" onClick={modalClose} />
      <div className="centered"> */}
      <Modal toggle={modalClose}>
        <Card sx={{ width: 500 }}>
          <CardContent>
            <Typography variant="h5" component="div">
              Edit ({row.name})
            </Typography>
            <PillBox list={allNames} updatingData={(data) => setAllNames(data)} name={row.name} />
          </CardContent>
          <CardActions>
            <Button size="small" onClick={finalEditAction}>
              Save
            </Button>
            <Button size="small" onClick={modalClose}>
              Close
            </Button>
          </CardActions>
        </Card>
      </Modal>
    </>
  );
};

export default EditGautraModal;
