import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import Box from '@material-ui/core/Box';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Input from '@material-ui/core/Input';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
// Icons
import { Button } from '@paljs/ui/Button';
import EditIcon from '@material-ui/icons/EditOutlined';
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';
import OpenWithOutlinedIcon from '@material-ui/icons/OpenWithOutlined';
import DoneIcon from '@material-ui/icons/DoneAllTwoTone';
// import Collapse from '@material-ui/icons/Collapse';

// import Typography from "@material-ui/icons/Typography";
import RevertIcon from '@material-ui/icons/NotInterestedOutlined';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';

import { getAllGautras, deleteGautraAPI, editGautraAPI, moveGautraAPI } from '../../actions/gautraAction';
import SearchBar from 'material-ui-search-bar';
//modal
import Modal from '../../components/editGautraModals/modal';
//Pill
import Pills from '../../components/pills/pills';
// import tableIcons from "./MaterialTableIcons";
import LoadingSpinner from '../loader';
import { toast } from 'react-toastify';
import EditGautraModal from '../../components/editGautraModals/modal';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
    overflowX: 'auto',
  },
  table: {
    minWidth: 650,
  },
  selectTableCell: {
    width: 60,
  },
  tableCell: {
    width: 130,
    height: 40,
  },
  input: {
    width: 130,
    height: 40,
  },
}));

// const createData = (name, calories, fat, carbs, protein) => ({
//   id: name.replace(" ", "_"),
//   name,
//   calories,
//   fat,
//   carbs,
//   protein,
//   isEditMode: false
// });

const CustomTableCell = ({ row, name, onManageEdit, setIsOpen }) => {
  let ans = Array.isArray(row[name]);
  const classes = useStyles();
  const { isEditMode } = row;
  if (isEditMode && name == 'all_names')
    return (
      <>
        <EditGautraModal row={row} onModalEdit={onManageEdit} setIsOpen={setIsOpen} />
      </>
    );
  return <TableCell>{ans ? <Pills list={row[name]} /> : row[name]}</TableCell>;
};
// export const editGautra = async (data: any) => {
//     try {
//       editGautraAPI(data, (response: any) => {
//         console.log("this is  response",response);
//       });
//     } catch (error) {
//       // setError('Something went wrong!');
//      // showLoader(false);
//     }
//   };

export default function index() {
  const [rows, setRows] = React.useState([]);
  const [previous, setPrevious] = React.useState({});
  const [open, setOpen] = React.useState(false);
  const [isEditMode, setEditMode] = useState(false);
  const [isLoading, showLoader] = useState(true);
  const classes = useStyles();
  const [searched, setSearched] = useState('');
  const [demo, setDemo] = useState([]);

  useEffect(() => {
    getGautras();
    // try {
    //   getAllGautras((response: any) => {
    //     let data = response.data.gautras.map((currentValue: { SNO: any }, Index: number) => {
    //       currentValue.SNO = Index + 1;
    //       return currentValue;

    //     });
    //     // setOriginalRows(data);
    //     console.log("data", data)
    //     setRows(data);
    //     setDemo(data);
    //     showLoader(false);

    //   });
    // } catch (error) {
    //   // setError('Something went wrong!');
    //   showLoader(false);
    // }
  }, []);
  const getGautras = async () => {
    const response = await getAllGautras();
    let data = response?.data?.gautras?.map((currentValue: { SNO: any }, Index: number) => {
      currentValue.SNO = Index + 1;
      return currentValue;
    });
    // setOriginalRows(data);
    console.log('data', data);
    setRows(data);
    setDemo(data);
    showLoader(false);
  };

  const deleteGautra = async (row: any) => {
    try {
      const response = await deleteGautraAPI(row._id);
      const remainingRows = rows.filter((obj) => obj?._id != response?.gautra_id);
      setRows(remainingRows);
    } catch (error) {
      // setError('Something went wrong!');
      showLoader(false);
    }
  };

  const moveGautra = async (rowId: any, oldName: string | null, newName: string | null) => {
    try {
      const data = {
        new_gautra: newName,
        old_gautra: oldName,
      };
      await moveGautraAPI(data);
    } catch (error) {
      // setError('Something went wrong!');
      showLoader(false);
    }
  };

  const onToggleEditMode = (id) => {
    setRows((state) => {
      return rows.map((row) => {
        if (row?._id === id) {
          return { ...row, isEditMode: !row?.isEditMode };
        }
        return row;
      });
    });
  };

  const onEditModeDone = (id, rowDetail) => {
    setRows((state) => {
      return rows?.map((row) => {
        if (row._id === id) {
          return { ...row, isEditMode: !row?.isEditMode };
        }
        return row;
      });
    });
  };

  const onManageEdit = async (data: any, gautraId) => {
    try {
      const response = await editGautraAPI(data, gautraId);
      console.log('this is response after modal edit done', response);
      if (response.status) toast.success('Updated Successfully');

      if (response && response.gautra_data) {
        setEditMode(false);
        setRows((state) => {
          return rows?.map((row) => {
            if (row?._id === gautraId) {
              return {
                ...row,
                all_names: response.gautra_data.all_names,
                display_name: response.gautra_data.display_name,
                isEditMode: !row?.isEditMode,
              };
            }
            return row;
          });
        });
      } else {
        toast.error('Invalid response structure:', response);
        console.error('Invalid response structure:', response);
      }
    } catch (error) {
      toast.error('Error in onManageEdit', error);
      console.error('Error in onManageEdit', error);
    }
  };

  // const onChange = (e, row) => {
  //   // console.log('--row--', row)
  //   if (!previous[row._id]) {
  //     setPrevious((state) => ({ ...state, [row._id]: row }));
  //   }
  //   const value = e.target.value;
  //   const name = e.target.name;
  //   const { _id } = row;
  //   const newRows = rows.map((row) => {
  //     if (row._id === _id) {
  //       return { ...row, [name]: value };
  //     }
  //     return row;
  //   });
  //   setRows(newRows);
  // };

  const onRevert = (id) => {
    const newRows = rows.map((row) => {
      if (row._id === id) {
        return previous[id] ? previous[id] : row;
      }
      return row;
    });
    setRows(newRows);
    setPrevious((state) => {
      delete state[id];
      return state;
    });
    onToggleEditMode(id);
  };
  const setIsOpen = (id) => {
    setRows((state) => {
      return rows?.map((row) => {
        if (row._id === id) {
          return { ...row, isEditMode: !row.isEditMode };
        }
        return row;
      });
    });
  };
  const requestSearch = (searchedVal: string) => {
    console.log('this is for searchva');
    const filteredRows = demo.filter((e) => {
      return JSON.stringify(e).indexOf(searchedVal) >= 0;
    });
    setRows(filteredRows);
  };
  const cancelSearch = () => {
    setSearched('');
    requestSearch(searched);
  };
  const manageGautra = (
    <Paper className={classes.root}>
      <SearchBar
        value={searched}
        onChange={(searchVal) => requestSearch(searchVal)}
        onCancelSearch={() => cancelSearch()}
      />
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            <TableCell align="left" />
            <TableCell align="left">Gautra Names</TableCell>
            <TableCell align="left">Display Gautra Names</TableCell>
            {rows?.length > 0 && rows[0]?.subcastes?.length > 0 ? (
              <TableCell align="left">Display subcastes Names</TableCell>
            ) : (
              ''
            )}
            {/* <TableCell align="left">Calories</TableCell>
            <TableCell align="left">Fat&nbsp;(g)</TableCell>
            <TableCell align="left">Carbs&nbsp;(g)</TableCell>
            <TableCell align="left">Protein&nbsp;(g)</TableCell> */}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows?.map((row, index) => (
            <>
              <TableRow key={row._id}>
                <TableCell className={classes.selectTableCell}>
                  {row.isEditMode ? (
                    <>
                      <IconButton aria-label="done" onClick={() => onEditModeDone(row._id, row)}>
                        <DoneIcon />
                      </IconButton>
                      <IconButton aria-label="revert" onClick={() => onRevert(row._id)}>
                        <RevertIcon />
                      </IconButton>
                    </>
                  ) : (
                    <>
                      <IconButton aria-label="edit" onClick={() => onToggleEditMode(row._id)}>
                        <EditIcon />
                      </IconButton>
                      <IconButton
                        aria-label="MoveUp"
                        onClick={() => {
                          const newGautra = window.prompt(`Are you sure you want to Move ${row.name}'s Gautra?`);
                          if (newGautra) {
                            moveGautra(row._id, row.name, newGautra);
                          }
                        }}
                      >
                        <OpenWithOutlinedIcon />
                      </IconButton>
                      <IconButton
                        aria-label="delete"
                        onClick={() => {
                          if (window.confirm(`Are you sure you want to delete ${row.name}'s?`)) deleteGautra(row);
                        }}
                      >
                        <DeleteOutlineOutlinedIcon />
                      </IconButton>
                    </>
                  )}
                </TableCell>
                <CustomTableCell {...{ row, name: 'name', onManageEdit, setIsOpen }} />
                <CustomTableCell {...{ row, name: 'all_names', onManageEdit, setIsOpen }} />
                {rows[0]?.subcastes[0] ? (
                  <CustomTableCell {...{ row, name: 'subcastes', onManageEdit, setIsOpen }} />
                ) : (
                  ''
                )}
              </TableRow>
              <TableRow></TableRow>
            </>
          ))}
        </TableBody>
      </Table>
    </Paper>
  );
  return <>{isLoading ? <LoadingSpinner message="Loading Data For Manage Gautra" /> : manageGautra}</>;
}
